import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { about, leads } from "../../constants"

import { FaLinkedinIn, FaInstagram } from "react-icons/fa"
import { members, orgAcronym, orgName } from "../../constants"

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "san-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1030) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      karl: file(relativePath: { eq: "karl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      danny: file(relativePath: { eq: "profile/DannyLi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eddy: file(relativePath: { eq: "profile/EddyLeung.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      linda: file(relativePath: { eq: "profile/LindaNguyen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sam: file(relativePath: { eq: "profile/SamTran.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mizuho: file(relativePath: { eq: "profile/MizuhoMiyagawa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mark: file(relativePath: { eq: "profile/MarkCatangui.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cathleen: file(relativePath: { eq: "profile/CathleenSun.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      christina: file(relativePath: { eq: "profile/ChristinaWang.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gavin: file(relativePath: { eq: "profile/GavinChing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      griffin: file(relativePath: { eq: "profile/griffin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jasmine: file(relativePath: { eq: "profile/JasmineMartinez.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kyra: file(relativePath: { eq: "profile/KyraLee.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lily: file(relativePath: { eq: "profile/LilyLi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      miriam: file(relativePath: { eq: "profile/MiriamYang.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      yibiao: file(relativePath: { eq: "profile/YibiaoWang.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laura: file(relativePath: { eq: "profile/LauraBagk.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jasmineN: file(relativePath: { eq: "profile/JasmineNauta.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ramya: file(relativePath: { eq: "profile/RamyaK.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dani: file(relativePath: { eq: "profile/DaniKang.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const map = data.map.childImageSharp.fluid
  const karl = data.karl.childImageSharp.fluid
  const leadsImg = [
    data.laura.childImageSharp.fluid,
    data.linda.childImageSharp.fluid,
    data.jasmineN.childImageSharp.fluid,
    // data.jessica.childImageSharp.fluid,
    data.mizuho.childImageSharp.fluid,
    data.mark.childImageSharp.fluid,
    // data.dorothy.childImageSharp.fluid,
    data.eddy.childImageSharp.fluid,
    // data.taylor.childImageSharp.fluid,
    data.cathleen.childImageSharp.fluid,
    data.danny.childImageSharp.fluid,
  ]
  const teamImg = [
    data.dani.childImageSharp.fluid,
    // data.jasmine.childImageSharp.fluid,
    data.kyra.childImageSharp.fluid,
    data.ramya.childImageSharp.fluid,
    data.sam.childImageSharp.fluid,
    // data.niki.childImageSharp.fluid,
    data.christina.childImageSharp.fluid,
    // data.christian.childImageSharp.fluid,
    data.yibiao.childImageSharp.fluid,
    // data.miriam.childImageSharp.fluid,
    data.gavin.childImageSharp.fluid,
    data.lily.childImageSharp.fluid,
    data.griffin.childImageSharp.fluid,
  ]
  return (
    <div className="about-container">
      <h4>The who, the what, and the why</h4>
      <h3>
        Local learnings, global growth<span className="period">.</span>
      </h3>
      <div className="mission">
        Our mission is to advance and empower Asians and other marginalized
        groups through advocacy and education together as a community
        <span className="period">.</span>
      </div>
      <div className="about-content">
        <div className="content">
          Back in 2018, we noticed a significant lack of content and
          representation in mainstream media that highlighted and featured
          people of color. <br />
          <br />
          With the status quo being challenged in our ever-changing political
          landscape, an opportunity arose for that gap to be filled.
          <br />
          <br />
          <strong>{orgName}</strong> was created to provide opportunities,
          resources, and a support network for Asians and those with
          intersectional and marginalized identities.
          <br />
          <br />
          We aim to create a safe space for our members to explore
          institutionalized and systemic racism in their careers and
          professional lives (e.g. job searching process, promotions, salaries,
          networking), while also examining internalized and generational
          racism.
        </div>
      </div>
      <div className="stat-header">Where are our members?</div>
      <div className="map-container">
        <div className="map">
          <Img
            fluid={map}
            alt="map chart of where SAN members are from (country & cities)"
          />
        </div>
        <div className="stat-container">
          <div className="stat">
            <div className="stat-main ">100+</div>
            <div className="stat-content">cities from around the world.</div>
          </div>
          <div className="stat">
            <div className="stat-main">{members}+</div>
            <div className="stat-content">members in our network.</div>
          </div>
          <div className="stat">
            <div className="stat-main">420+</div>
            <div className="stat-content">companies our members work in.</div>
          </div>
        </div>
      </div>

      <div className="subheader">
        Meet the {orgAcronym} Team
        <span className="period">.</span>
      </div>
      <div className="team-container">
        <div className="member">
          <Img className="member-img" fluid={karl} alt="Picture of Karl" />
          <div className="member-name">Karl Chen</div>
          <div className="member-pronouns">(he/they)</div>
          <div className="member-title">Founder</div>
          <div className="member-links">
            <a
              href={"https://www.linkedin.com/in/karl-chen/"}
              rel="noopener"
              target="_blank"
            >
              <FaLinkedinIn />
            </a>
            <a
              href={"https://www.instagram.com/uwuloo/"}
              rel="noopener"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
      <div className="team-container">
        {leads.map((member, i) => (
          <div className="member" key={member.name}>
            <Img
              className="member-img object-cover"
              fluid={leadsImg[i]}
              alt={`Picture of ${member.name}`}
            />
            <div className="member-name">{member.name}</div>
            <div className="member-pronouns">({member.pronouns})</div>
            <div className="member-title">{member.title}</div>
            <div className="member-links">
              {member.socials.map(social => (
                <a href={social.link} rel="noopener" target="_blank">
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="team-container">
        {about.map((member, i) => (
          <div className="member" key={member.name}>
            <Img
              className="member-img object-cover"
              fluid={teamImg[i]}
              alt={`Picture of ${member.name}`}
            />
            <div className="member-name">{member.name}</div>
            <div className="member-pronouns">({member.pronouns})</div>
            <div className="member-title">{member.title}</div>
            <div className="member-links">
              {member.socials.map(social => (
                <a href={social.link} rel="noopener" target="_blank">
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
